@import 'src/index.scss';

.fa-times-thin:before {
	content: '\00d7';
}

.business-title-wrap {
	@media (max-width: 400px) {
		flex-direction: column !important;
		justify-content: flex-start !important;
		align-items: flex-start !important;
	}
}

.add-business-btn {
	@media (max-width: 400px) {
		align-self: flex-end;
	}
}