@import 'src/index.scss';

.service-details-card {
    width: 49% !important;

    &:nth-child(odd) {
        margin-right: 2% !important;
    }

    @media (max-width: 1000px) {
        width: 100% !important;
        

        &:nth-child(odd) {
            margin-right: 0 !important;
        }
    }
}