@import 'src/index.scss';

.key-container {
    @media (max-width: 420px) {
        width: 75% !important
    }

    @media (max-width: 320px) {
        width: 70% !important
    }
}

.key-copy-container {
    @media (max-width: 420px) {
        width: 25% !important
    }

    @media (max-width: 320px) {
        width: 30% !important
    }

    &:hover {
        span {
            color: #0D968F !important
        }
    }
}

.key-explainer {
    // @media (max-width: 600px) {
    //     display: none !important
    // }
}

.business-key-card {
    width: 49% !important;

    &:not(:last-child) {
        margin-right: 2% !important;
    }

    @media (max-width: 1300px) {
        width: 100% !important;
        

        &:not(:last-child) {
            margin-right: 0 !important;
        }
    }
}

.api-keys {
    width: 32rem !important;
    overflow-x: auto;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none
    }

    @media (max-width: 1500px) {
        width: 28rem !important;
    }

    @media (max-width: 1400px) {
        width: 23rem !important;
    }

    @media (max-width: 1300px) {
        width: 50rem !important;
    }

    @media (max-width: 1115px) {
        width: 30rem !important;
    }

    @media (max-width: 500px) {
        width: 25rem !important;
    }
    @media (max-width: 440px) {
        width: 20rem !important;
    }

    @media (max-width: 410px) {
        width: 15rem !important;
    }
    @media (max-width: 360px) {
        width: 10rem !important;
    }
}