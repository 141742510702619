@import 'src/index.scss';

.decision-box-container {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    background-color: #ffffff !important;
    transform: translate(-50%, -50%) !important;
    padding: 2rem !important;
    width: 40rem !important;
    min-height: 22rem !important;
    z-index: 10 !important;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03) !important;
    border-radius: 1.2rem !important;

    @media (max-width: 380px) {
        width: 93% !important;
    }
}

.decision-box-btn-wrapper {
    @media (max-width: 341px) {
        button {
            width: 100% !important;
        }
    }
}