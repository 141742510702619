@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;600;700;800;900;1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600;700;800;900;1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@600;700;800;900;1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

$color-white: #ffffff;
$theme-secondary-color: #0D968F;
$theme-background: #F9FAFB;
$theme-light-color: #0A0039;

@font-face {
  font-family: 'Gilmer';
  src: local('Gilmer'), url(./fonts/Gilmer/Gilmer-Regular.otf) format('truetype');
}

@font-face {
  font-family: 'Gilmer-Semi-Bold';
  src: local('Gilmer-Semi-Bold'), url(./fonts/Gilmer/Gilmer-Medium.otf) format('truetype');
}

@font-face {
  font-family: 'Gilmer-Bold';
  src: local('Gilmer-Bold'), url(./fonts/Gilmer/Gilmer-Bold.otf) format('truetype');
}

@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (max-width: 37.5em) {
      @content;
    } //600px
  }
  @if $breakpoint == tab-port {
    @media (max-width: 56.25em) {
      @content;
    } //900px
  }
  @if $breakpoint == tab-land {
    @media (max-width: 75em) {
      @content;
    } //1200px
  }
  @if $breakpoint == big-desktop {
    @media (min-width: 112.5em) {
      @content;
    } //1800px
  }
}

@mixin zero-space {
  margin: 0;
  padding: 0;
}
@mixin side-space {
  margin: 0 1.4rem;
}
@mixin flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@mixin flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@mixin flex-row-centered {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center
}


@keyframes pulsation {
  from {
    transform: scale(.9)
  }
  to {
    transform: scale(1.3)
  }
}


* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html, body {
  font-size: 62.5%;
  height: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;

  @include respond(tab-port) {
    font-size: 53%;
  }

  @include respond(phone) {
    font-size: 50%; // 1rem = 8px, 8/16 * 100 = 50%
  }
}
  
input,
textarea,
button,
select,
a,
label,
span,
div,
p{
    -webkit-tap-highlight-color: rgba(0,0,0,0)
}


body {
  margin: 0;
  //font-family: benton-sans,'Helvetica Neue',helvetica,arial,sans-serif;
  //font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-family: Gilmer, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  font-size: 1.5rem;
  font-style: normal;
  color: #667085;
  background-color: $theme-background;

  overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    //z-index: 4;

    &::-webkit-scrollbar {
        display: none
    }
}

code {
  //font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.input-with-icon {
  border: 1px solid #D0D5DD;
  border-radius: .8rem;
  background-color: transparent;
  //padding: 0 1.5rem;
  color:  #667085;
  height: 4.4rem;
  width: 100%;
  position: relative;
  
  input, select {
      height: 4.4rem;
      border: none;
      color: #667085;
      background: transparent;
      width: 100%;
      padding: 0 1.5rem; // new
      outline: none;
      font-size: 1.6rem;
  }

  select {
      -webkit-appearance: none;
      appearance: none;
      //new
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;

      option {
          color: rgba(#000, .8)
      }
  }

  .amount-input {
    padding-left: 3rem;
  }

  .input-with-icon-tag, span, .amount-symbol{
      position: absolute;
      top: 50%;
      right: 2rem;
      transform: translateY(-50%);
      font-size: 1.7rem;
  }

  span {
      font-size: 1.3rem;
      font-weight: 400
  }

  .amount-symbol {
      width: 2.5rem;
      left: 1.5rem;
      font-size: 1.7rem;
  }
}

.icon-component {
  @media (max-width: 900px) {
      transform: scale(0.9);
  }
  @media (max-width: 600px) {
      transform: scale(0.85);
  }
}

.scrollable-container {
  width: 100%;
  overflow-x: auto;
  overflow: auto !important;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
      display: none
  }
}