@import 'src/index.scss';

.top-transactions {
    margin-right: 1.8% !important;
    &:last-child {
        margin-right: 0 !important;
    }

    @media (max-width: 1370px) {
        // width: 20rem !important;
        // margin-right: 1.7 !important;
        width: 32.1% !important;

        &:nth-child(3) {
            margin-right: 0 !important;
        }
    }

    @media (max-width: 600px) {
        margin-right: 2.5% !important;
        width: 48.75% !important;

        &:nth-child(even) {
            margin-right: 0 !important;
        }

        &:nth-child(3) {
            margin-right: 2.5% !important;
        }
    }

    @media (max-width: 386px) {
        width: 100% !important;
        margin-right: 0 !important;
        
        &:nth-child(3) {
            margin-right: 0 !important;
        }
    }
}

.txChart-products {
    @media (max-width: 1060px) {
        flex-direction: column !important;
        justify-content: flex-start !important;
    }
}

.chart-container {
    margin-right: 1.8% !important;
    @media (max-width: 1060px) {
        width: 100% !important;
        margin-bottom: 3rem !important;
    }
}
.top_products-container {
    @media (max-width: 1060px) {
        width: 100% !important;
    }
}

.toggler {
    &:hover {
        background-color: #E7F6F5 !important;

        span {
            color: #0A756F !important;
        }
    }
}

.tx-type-label {
    @media (max-width: 500px) {
        gap: 2rem !important;
    }
}

.tx-view-all {
    @media (max-width: 500px) {
        align-self: flex-end !important;
        flex-basis: auto !important; 
        flex-shrink: 0 !important; 
        flex-grow: 0 !important;
        margin-bottom: 1rem !important;
    }
}