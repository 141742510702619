@import 'src/index.scss';

.login-content {
    z-index: 2;

    @media (max-width: 377px) {
        width: 90% !important
    }
}

.rememberme-fPassword {
    @media (max-width: 365px) {
        flex-direction: column !important;
        justify-content: flex-start !important;
        align-items: flex-start !important;
    }

    .forget-password {
        @media (max-width: 365px) {
            margin-top: 1rem;
            padding-left: 0;
        }
    }
}
.remember-checkbox {
    @include flex-row;
    width: 1.8rem;
    height: 1.8rem;
    border-radius: .4rem;
    border: 1px solid #D0D5DD;
    transition: all .2s;

    &:hover {
        border-color: #0D968F;
        background-color: rgba(#0D968F, .3);
    }
}

.isremember-me {
    border-color: #0D968F;
    background-color: rgba(#0D968F, .1);
}

.checkbox-icon {
    font-size: 2rem;
    color: #0D968F
}
