@import 'src/index.scss';

.table-wrapper {
    width: 100%;
    font-size: 1.4rem;
    font-weight: 600;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    background-color: #ffffff;
    border: 1px solid #EAECF0;
    border-radius: .8rem;
    padding: 0rem 0 1rem 0
    //margin: 1rem auto 1.5rem auto;
}

.table-wrapper-no-radius {
    width: 100%;
    font-size: 1.4rem;
    font-weight: 600;
    background-color: #ffffff;
    //margin: 1rem auto 1.5rem auto;
}
.filter-search-wrapper {
    @include flex-row;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 1.5rem;

    label {
        font-size: 1.5rem;
        padding-right: 1.2rem;
        color: rgba(#000, .7)
    }

    .filter-input {
        width: 20rem;
        height: 3.2rem;
        padding: 0 1.5rem;
        font-size: 1.5rem;
        border: 1.2px solid rgba(#000, .5);
        border-radius: .6rem;
        outline: none;
        color: rgba(#000, .7)
    }
}