@import 'src/index.scss';

.service-search-box {
    background-color: #ffffff !important;
    @media (max-width: 355px) {
        width: 100% !important;
    }
}

.radio-selection-container {
    border-radius: .8rem !important;
    display: grid !important;
    place-items: center;
    padding: 0.5rem 1rem !important;

    @media (max-width: 400px) {
        width: 100% !important;

    }
}

.checkbox-selection-container {
    border-radius: .8rem !important;
    display: grid !important;
    place-items: center;
    padding: 0.5rem 1rem !important;
}

.checkbox-check-wrap {
    @include flex-row;
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 1.5rem;
    border-radius: .4rem;
    border: 1px solid #D0D5DD;
    transition: all .2s;
}

.checkbox-check-clicked {
    border-color: #0D968F;
    //background-color: rgba(#0D968F, .1);
}

.checkbox-check-icon {
    font-size: 1.3rem; // 2rem
    color: #0D968F
}

.checkbox-selection-mobile {
    @media (max-width: 370px) {
        width: 100% !important;
    }
}