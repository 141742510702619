@import "src/index.scss";

.business-partner-details-wrap {
	@media (max-width: 530px) {
		flex-direction: column !important;
		justify-content: flex-start !important;
		align-items: flex-start !important;

        button {
            align-self: flex-end;
        }
	}
}