@import 'src/index.scss';

.dashboard-container {
    width: 100%;
    height: 100%;
    padding-top: 3rem;
    padding-left: 27rem;
    background-color: $theme-background;

    &::-webkit-scrollbar {
        display: none
    }

    @media (max-width: 770px) {
        padding-left: 0;
    }
}

.dashboard-modules {
    // width: 100%;
    // height: 100%;
}

.menubar-hack {
    @media (max-width: 770px) {
        height: 4rem !important;
        width: 100% !important;
    }
}