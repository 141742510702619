@import 'src/index.scss';

.billing-circle-container {
    @media (max-width: 335px) {
        p {
            text-align: left !important;
        }
    }
}

.billing-splitted-card {
    &:first-child {
        margin-right: 1% !important;
    }
    @media (max-width: 1000px) {
        width: 100% !important;
        &:first-child {
            margin-right: 0 !important;
            margin-bottom: 2rem !important;
        }
    }
}

.billing-utilisation {
    @media (max-width: 500px) {
        justify-content: center !important;
    }
}

.payment-method-type {
    &:hover {
        border: 1px solid #0D968F !important;
        background-color: #E7F6F5 !important;

        span {
            color: #085B56 !important;
        }
    }
}

.otp-container {
    @media (max-width: 400px) {
        width: 100% !important;

        .resend-otp {
            place-items: center !important;
        }
    }
}