@import 'src/index.scss';

.code-verification {
    width: 8rem;
    height: 8rem;
    border: 1px solid #5EC3BD;
    border-radius: .8rem;
    background-color: transparent;
    color: #0D968F;
    text-align: center;
    font-size: 3.5rem;
    font-weight: 700;
    padding-top: 0;
    outline: none;
    display: grid;
    place-items: center;

    &:not(last-child) { 
        margin-right: 1rem
    }

    @media (max-width: 350px) {
        width: 6rem;
        height: 6rem;
        font-size: 2.5rem;
    }

    @media (max-width: 300px) {
        width: 5rem;
        height: 5rem;
        font-size: 2rem;
    }
}

.display-hidden {
    position: absolute;
    font-size: 0.5rem;
    width: 4rem;
    height: 0;
    opacity: 0;
}

.display-hidden:focus ~ .active-pin-box {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF;
}