@import 'src/index.scss';

.business-details-title-wrap {
	@media (max-width: 670px) {
		flex-direction: column !important;
		justify-content: flex-start !important;
		align-items: flex-start !important;
	}
}

.actions-business-details-btn {
	@media (max-width: 670px) {
		align-self: flex-end;
	}

    @media (max-width: 310px) {
		width: 100% !important;
        flex-wrap: wrap !important;
        justify-content: flex-start !important;
	}
}

.business-details-sections {
    @media (max-width: 500px) {
        width: 100% !important;
        margin-right: 0 !important;
    }
}

.services-accordion {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;

    overflow-y: scroll !important;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none
    }
}

.business-details-card {
    width: 49% !important;

    &:not(:last-child) {
        margin-right: 2% !important;
    }

    @media (max-width: 1030px) {
        width: 100% !important;
        

        &:not(:last-child) {
            margin-right: 0 !important;
        }
    }
}